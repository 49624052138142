<template>
    <div id="page-nilai-rujukan-lab">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Tambah Data Nilai Rujukan Lab</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="12" md="12" lg="12" xl="12">
                  <b-form>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Parameter Pemeriksaan <span class="text-danger">*</span>
                      </template>
                      <multiselect
                        :options="list_sub_tindakan"
                        :state="checkIfValid('sub_tindakan_lab')"
                        v-model="$v.is_data.sub_tindakan_lab.$model"
                        :multiple="false"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        label="nama_sub_tindakan_lab"
                        track-by="sub_tindakan_lab_id"
                        placeholder="-- Pilih Parameter --"
                        size="sm"
                      ></multiselect>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Jenis Kelamin <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :options="jenis_kelamin"
                        :state="checkIfValid('jenis_kelamin_lab')"
                        v-model="$v.is_data.jenis_kelamin_lab.$model"
                        placeholder="-- Pilih Jenis Kelamin --"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Dari Umur 
                      </template>
                      <div style="width: 100%;display: flex;">
                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Tahun</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.dari_umur_tahun"
                            type="number"
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;margin:0 10px">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bulan</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.dari_umur_bulan"
                            type="number"
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.dari_umur_hari"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Sampai Umur 
                      </template>
                      <div style="width: 100%;display: flex;">
                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Tahun</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.sampai_umur_tahun"
                            type="number"
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;margin:0 10px">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bulan</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.sampai_umur_bulan"
                            type="number"
                          ></b-form-input>
                        </div>

                        <div style="width:33.33%;display: flex;">
                          <div style="width:25%;height: 35px;background-color: #b9bec7;color:#4f5d73;border-top-left-radius: 0.25rem;border-bottom-left-radius: 0.25rem;display: flex;justify-content: center;align-items: center;">
                            <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                          </div>
                          <b-form-input
                            v-model="is_data.sampai_umur_hari"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Tipe Nilai <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :options="options_nilai"
                        :state="checkIfValid('tipe_nilai')"
                        v-model="$v.is_data.tipe_nilai.$model"
                        placeholder="-- Pilih Nilai --"
                      ></b-form-select>
                    </b-form-group>
                  </b-form>
                </b-col>
                <b-col v-if="!submit">
                  <b-button :disabled="!isValid" variant="success" @click="simpanData()">Simpan</b-button>
                </b-col>
              </b-row>
              <hr>
              <b-row v-if="submit">
                <b-col cols="12" md="12" lg="12" xl="12">
                  <b-form>
                    <b-form-group label-cols-md="3" v-if="is_data.tipe_nilai == 'angka'">
                      <template v-slot:label>
                        Nilai Minimum <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :state="checkIfValidSub('nilai_minimum')"
                        v-model="$v.is_sub_data.nilai_minimum.$model"
                        type="number"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="3" v-if="is_data.tipe_nilai == 'angka'">
                      <template v-slot:label>
                        Nilai Maximum <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :state="checkIfValidSub('nilai_maximum')"
                        v-model="$v.is_sub_data.nilai_maximum.$model"
                        type="number"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="3" v-if="is_data.tipe_nilai == 'text'">
                      <template v-slot:label>
                        Nilai Normal Text <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        :state="checkIfValidSub('nilai_normal_text')"
                        v-model="$v.is_sub_data.nilai_normal_text.$model"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Nilai Normal <span class="text-danger">*</span>
                      </template>
                      <b-form-radio-group
                        :options="options"
                        v-model="is_sub_data.nilai_laborat"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                        @change="setNilai(is_sub_data.nilai_laborat)"
                        style="margin-bottom: 0 !important;"
                      ></b-form-radio-group>
                      <b-form-input
                        v-model="is_sub_data.nilai_lab"
                        type="text"
                        @input="reset()"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Keterangan
                      </template>
                      <b-form-textarea
                        v-model="is_sub_data.keterangan_nilai_lab"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-form>
                </b-col>
                <b-col>
                  <b-button :disabled="!isValidSub" variant="success" @click="simpanDataSub()">Simpan Data</b-button>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col>
                  <b-table
                      :items="sub_nilai_lab"
                      :fields="fields"
                      responsive
                      show-empty
                      small
                      hover
                  >
                  <template #cell(actions)="row">
                      <b-button
                          variant="danger"
                          class="mr-1"
                          v-c-tooltip.hover.click="'Hapus Data'"
                          v-b-modal.modal-delete-sub-nilai
                          @click="datanya = row.item"
                          ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
                      >
                  </template>
                  
                  </b-table>
                </b-col>
              </b-row>
              <!-- <b-row>
                  <b-col cols="12" sm="12" md="12" lg="12" offset-md="10" offset-sm="10" offset-lg="10" class="mt-3">
                    <b-button :disabled="!isValid || disabeled" variant="success" @click="simpanData()">Simpan Data</b-button>
                  </b-col>
              </b-row> -->
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <ModalDelete
        :data_delete="datanya"
        @alertFromChild="triggerAlert($event)"
        @tutupModal="getDatas()"
      />
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import ModalDelete from "./modal_delete_sub.vue"
  export default {
    name: "inpu_nilai_rujukan",
    components: {
      ModalDelete
    },
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        list_sub_tindakan: [

        ],
        disabeled: false,
        submit: false,
        datanya: "",
        ms_nilai_lab_id: null,
        jenis_kelamin: [
          {value: 'male', text: 'Male'},
          {value: 'female', text: 'Female'},
          {value: 'all', text: 'All'},
        ],

        options_nilai: [
          {value: 'text', text: 'Text'},
          {value: 'angka', text: 'Angka'},
        ],

        options: [
          { item: 'normal', name: 'Normal' },
          { item: 'negatif', name: 'Negatif' },
          { item: 'positif', name: 'Positif' },
          { item: 'reactive', name: 'Reactive' },
          { item: 'non_reactive', name: 'Non Reactive' },
        ],
        is_data: {
          dari_umur_bulan: 0,
          dari_umur_hari: 0,
          dari_umur_tahun: 0,
          sampai_umur_bulan: 0,
          sampai_umur_hari: 0,
          sampai_umur_tahun: 0,
          jenis_kelamin_lab: null,
          tipe_nilai: null,
          sub_tindakan_lab_id: null,
          sub_tindakan_lab:null,
        },
        is_sub_data: {
          nilai_minimum: null,
          nilai_maximum: null,
          nilai_normal_text: null,
          nilai_lab: null,
          keterangan_nilai_lab: null,
          nilai_laborat: null,
        },
        sub_nilai_lab: [],
        fields: [
          {
            key: "nilai",
            label: "Nilai",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "nilai_lab",
            label: "Hasil Nilai Lab",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
      formStringSub() {
        return JSON.stringify(this.is_sub_data, null, 4);
      },
      isValidSub() {
        return !this.$v.is_sub_data.$invalid;
      },
      isDirtySub() {
        return this.$v.is_sub_data.$anyDirty;
      },
    },
    mixins: [validationMixin],
    validations: {
      is_data: {
        jenis_kelamin_lab: { required },
        tipe_nilai: { required },
        sub_tindakan_lab: { required },
      },
      is_sub_data: {
        nilai_maximum: { 
          required: requiredIf(function() {
            if(this.is_data.tipe_nilai == 'angka'){
              return true
            }
          }) 
        },
        nilai_minimum: { 
          required: requiredIf(function() {
            if(this.is_data.tipe_nilai == 'angka'){
              return true
            }
          }) 
        },
        nilai_normal_text: { 
          required: requiredIf(function() {
            if(this.is_data.tipe_nilai == 'text'){
              return true
            }
          }) 
        },
        nilai_lab: { required },
      },
    },
    activated(){
      this.getData()
      this.resetData()
      this.resetDataSub()
    },
    async mounted() {
      // Set the initial number of items
      this.getData()
    },
    methods: {
      reset(){
        let vm = this
        vm.is_sub_data.nilai_laborat = null
      },
      setNilai(x){
        let vm = this
        vm.is_sub_data.nilai_lab = x
      },
      async getData(){
        let vm = this
        let res2 = await vm.$axios.post("/sub_tindakan_lab/list");
          // console.log(res2, 'ini ressnya');
        vm.list_sub_tindakan = res2.data.data
      },
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      checkIfValidSub(fieldName) {
        const field = this.$v.is_sub_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async getDetail(){
        let vm = this
        let detail = await vm.$axios('/ms_nilai_lab/details_by_id/' + vm.ms_nilai_lab_id)
        console.log(detail, 'ini detail');
        if(detail.data.data[0].sub_nilai_lab.length){
          vm.sub_nilai_lab = detail.data.data[0].sub_nilai_lab
          for (let i = 0; i < vm.sub_nilai_lab.length; i++) {
            let x = vm.sub_nilai_lab[i];
            if(x.nilai_normal_text){
              x.nilai = x.nilai_normal_text
            }else {
              x.nilai = `${x.nilai_minimum} - ${x.nilai_maximum}`
            }
          }
        }else {
          vm.sub_nilai_lab = []
        }
      },
      simpanData(){
        let vm = this
        vm.is_data.sub_tindakan_lab_id = vm.is_data.sub_tindakan_lab.sub_tindakan_lab_id
        vm.is_data.dari_umur_bulan = parseInt(vm.is_data.dari_umur_bulan)
        vm.is_data.dari_umur_tahun = parseInt(vm.is_data.dari_umur_tahun)
        vm.is_data.dari_umur_hari = parseInt(vm.is_data.dari_umur_hari)
        vm.is_data.sampai_umur_bulan = parseInt(vm.is_data.sampai_umur_bulan)
        vm.is_data.sampai_umur_tahun = parseInt(vm.is_data.sampai_umur_tahun)
        vm.is_data.sampai_umur_hari = parseInt(vm.is_data.sampai_umur_hari)
        vm.$axios.post("/ms_nilai_lab/register", vm.is_data)
        .then((res) => {
          console.log(res, 'ini ress');
          if(res.data.status == 200 && res.data.message == 'sukses'){
            vm.submit = true
            vm.ms_nilai_lab_id = res.data.data.id
            vm.getDetail()
          }else{
            vm.$store.commit('set_alert', {msg: res.data.message, variant: "danger", showing: true})
          }
        })
        .catch((err) => {
          console.log(err);
          vm.$store.commit('set_alert', {msg: "TERJADI KESALAHAN PADA SERVER", variant: "danger", showing: true})
        })
      },
      simpanDataSub(){
        let vm = this
        vm.is_sub_data.ms_nilai_lab_id = vm.ms_nilai_lab_id
        // vm.is_sub_data.nilai_minimum = parseInt(vm.is_sub_data.nilai_minimum)
        // vm.is_sub_data.nilai_maximum = parseInt(vm.is_sub_data.nilai_maximum)
        vm.$axios.post("/sub_nilai_lab/register", vm.is_sub_data)
        .then((res) => {
          console.log(res, 'ini ress sub tindakan');
          if(res.data.status == 200 && res.data.message == 'sukses'){
            vm.$store.commit('set_alert', {msg: "BERHASIL MENAMBAHKAN NILAI LAB", variant: "success", showing: true})
            vm.getDetail()
          }else{
            vm.$store.commit('set_alert', {msg: res.data.message, variant: "danger", showing: true})
          }
        })
        .catch((err) => {
          console.log(err);
          vm.$store.commit('set_alert', {msg: "TERJADI KESALAHAN PADA SERVER", variant: "danger", showing: true})
        })
      },
      resetData(){
        let vm = this
        vm.is_data = {
          dari_umur_bulan: 0,
          dari_umur_hari: 0,
          dari_umur_tahun: 0,
          sampai_umur_bulan: 0,
          sampai_umur_hari: 0,
          sampai_umur_tahun: 0,
          jenis_kelamin_lab: null,
          tipe_nilai: null,
          sub_tindakan_lab_id: null,
        }
        vm.sub_nilai_lab = []
      },
      resetDataSub(){
        let vm = this
        vm.is_sub_data = {
          nilai_minimum: null,
          nilai_maximum: null,
          nilai_normal_text: null,
          nilai_lab: null,
          keterangan_nilai_lab: null,
        }
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDetail()
      },
    },
  };
  </script>

<style scoped>
  .input-group-text{
    font-size: 12px;
  }

 
</style>
  